<template>
  <div class="swiper-navigation">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.swiper-navigation {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: var(--widthPadding);
  gap: 8px;
  z-index: 10;

  &:deep(button) {
    @include reset-button;
    width: 32px;
    height: 32px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath fill='%231F2229' fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    border-radius: 8px;
    &:nth-child(1) {
      transform: rotate(180deg);
    }
  }
  @include md {
    display: flex;
  }
}
</style>
