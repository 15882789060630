import { scroller } from "vue-scrollto/src/scrollTo";

export default {
  data() {
    return {
      scrollTo: scroller(),
    };
  },
  methods: {
    scroll(section) {
      this.scrollTo(section);
    },
  },
};
