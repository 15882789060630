<template>
  <div class="map">
    <iframe
      class="map__frame"
      src="https://msk.t2.ru/coverage?mode=webView"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "SectionMap",
};
</script>

<style lang="scss" scoped>
.map {
  margin-top: var(--widthGapH);
  &__frame {
    overflow: hidden;
    width: 100%;
    border: none;
    display: block;
    border-radius: 0 0 var(--radiusOuter) var(--radiusOuter);
    height: 600px;
    @include md {
      height: 740px;
    }
  }
}
</style>
