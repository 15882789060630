<template>
  <footer class="footer">
    <div class="footer__inner">
      <p>
        Информация строго конфиденциальна и не подлежит передаче третьим лицам.
      </p>
      <p>© t2 Россия, {{ new Date().getFullYear() }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped lang="scss">
.footer {
  background: var(--colorBlack);
  display: grid;
  place-items: center;
  color: var(--colorWhite);
  padding: 0 var(--widthGutter);
  text-align: center;
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    padding: 32px 0;
    p {
      margin: 0;
      font-size: 10px;
      line-height: 1.2;
      font-weight: 500;
    }
  }
}
</style>
