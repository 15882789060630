<template>
  <div class="modal" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <div v-if="show" class="modal-content">
        <button class="modal__close" @click="close()"></button>
        <div class="modal__body">
          <div class="modal__text">
            {{ text }}
          </div>
          <div class="modal-nav">
            <ul>
              <li v-for="(item, index) in nav" :key="index">
                <button
                  @click="onClickMenuElement(item.section)"
                  v-html="item.name"
                ></button>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal__foot">
          <button @click="$emit('show-location')" class="modal-location">
            {{ current || "Москва и область" }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="show" class="modal-backdrop"></div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModalNav",
  emits: ["close", "show-location"],
  props: {
    text: {
      type: String,
    },
    to: {
      type: String,
    },
    location: {
      type: Boolean,
    },
    current: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      nav: (state) => state.nav.list,
    }),
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    document.body.classList.add("modal-open");
    document.addEventListener("keyup", this.keyPressEscape);
  },
  mounted() {
    this.show = true;
  },
  unmounted() {
    document.body.classList.remove("modal-open");
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  methods: {
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.close();
      }
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
    onNext() {
      this.close();
      this.$router.push(this.to);
    },
    onClickMenuElement(section) {
      this.close();
      this.scroll(section);
    },
  },
};
</script>

<style></style>
<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: var(--colorBlack);
  &-dialog {
    --height: var(--heightHeader);
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    margin: 0 auto;
    margin-top: var(--height);
    height: calc(100% - var(--height));
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--colorWhite);
    background-clip: padding-box;
    outline: 0;
    padding: 0 var(--widthGutter);
    height: 100%;
    justify-content: space-between;
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
  }
  &__head {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__close {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 45px;
    height: var(--heightHeader);
    position: fixed;
    top: 0;
    right: 2px;
    z-index: 11110;
    // &::before {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   content: "";
    //   z-index: -1;
    //   cursor: default;
    // }
  }
  &__text {
    font-size: 14px;
  }
  &__button {
    @include reset-button;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    background-color: var(--colorWhite);
    border-radius: 24px;
    font-weight: bold;
    font-size: 16px;
    color: var(--colorBlack);
    height: 48px;
  }
  &-nav {
    ul {
      @include reset-list;
      margin-top: 24px;
      li {
        margin-top: 8px;
        border-bottom: 1px solid #ededed;
        button {
          @include reset-button;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #959597;
          padding: 8px 0;
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  &__foot {
    background: #f3f5f6;
    margin: 0 calc(-1 * var(--widthGutter));
    padding: 16px var(--widthGutter);
  }
  &-location {
    @include reset-button;
    font-size: 16px;
    line-height: 26px;
    color: #959597;
    width: 100%;
    text-align: left;
    padding-left: 28px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 9.75c0 1.8873-.59 3.5289-1.8026 5.413-1.1331 1.7608-2.7777 3.6866-4.9474 6.1918-2.1697-2.5052-3.8143-4.431-4.9474-6.1918C5.84 13.2789 5.25 11.6373 5.25 9.75 5.25 6.022 8.272 3 12 3c3.7279 0 6.75 3.022 6.75 6.75Z' stroke='%23959597' stroke-width='1.5'/%3E%3Ccircle cx='12' cy='9.75' r='3' stroke='%23959597' stroke-width='1.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.2s;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
