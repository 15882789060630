<template>
  <div :id="`content-swiper-${$.uid}`" class="content-swiper">
    <swiper
      :modules="[Navigation, Pagination]"
      :slides-per-view="
        breakpointsMobile.includes(screenSize) ? slidesMobile : slidesDesktop
      "
      :space-between="16"
      :slidesOffsetBefore="
        breakpointsMobile.includes(screenSize) ? slidesOffsetMobile : 0
      "
      :slidesOffsetAfter="
        breakpointsMobile.includes(screenSize) ? slidesOffsetMobile : 0
      "
      :navigation="{
        nextEl: `#content-swiper-${$.uid} .button-next`,
        prevEl: `#content-swiper-${$.uid} .button-prev`,
      }"
      :pagination="{
        el: `#content-swiper-${$.uid} .swiper-custom-pagination`,
      }"
      @swiper="onSwiper"
    >
      <slot />
    </swiper>
    <SwiperNavigation
      v-show="slides > slidesDesktop"
      class="swiper-custom-navigation"
    >
      <button type="button" class="button-prev"></button>
      <button type="button" class="button-next"></button>
    </SwiperNavigation>
    <SwiperPagination class="swiper-custom-pagination" />
  </div>
</template>

<script setup>
import { Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/vue";
import { ref } from "vue";
import SwiperNavigation from "./SwiperNavigation.vue";
import SwiperPagination from "./SwiperPagination.vue";

defineProps({
  slidesOffsetMobile: {
    type: Number,
    default: 12,
  },
  slidesDesktop: {
    type: Number,
    default: 3,
  },
});

const slidesMobile = 1;
const slides = ref();

const onSwiper = (swiper) => {
  slides.value = swiper.slides.length;
};
</script>

<style lang="scss" scoped>
.content-swiper {
  position: relative;
  display: grid;
  max-width: 1004px;
  margin: 0 calc(var(--widthPadding) * -1);
  &.white {
    // padding: 0 var(--widthPadding);
    &:deep() {
      .step-item__number {
        background: var(--colorPrimaryText);
        @include md {
          background: #e3e6e9;
        }
      }
      .swiper-pagination span {
        background: rgba(255, 255, 255, 0.3);
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
      }
    }
    @include md {
      color: var(--colorPrimaryText);
    }
  }
  @include md {
    margin: 0;
  }
  &:deep(.swiper) {
    width: 100%;
    .content-item {
      width: calc(100% - var(--widthPadding) * 2);
      @include md {
        width: 100%;
      }
    }
  }
  &:deep(.swiper-custom-navigation) {
    @include md {
      position: relative;
      filter: invert(1);
      margin: 24px auto 0;
    }
  }
  &:deep(.swiper-custom-pagination) {
    @include md {
      display: none;
    }
  }
}
</style>
