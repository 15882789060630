export default {
  namespaced: true,
  state: {
    faq: [],
  },
  mutations: {
    setFAQ(state, value) {
      state.faq = value;
    },
  },
};
