<template>
  <div class="bonuses">
    <PromoItem
      class="bonuses__head"
      :color="promo.color"
      :title="store.state.sellers.sellers.title"
      :images="promo.images"
    />
    <div class="bonuses__body">
      <Collapse color="grey">
        <template #head>Что за скидка</template>
        <template #body>
          <div
            v-for="(item, index) in store.state.sellers.sellers.discounts"
            :key="index"
            class="bonuses__grid"
          >
            <ContentNumber v-if="item.image">
              <img :src="mediaUrl(item.image)" width="32" height="32" alt="" />
            </ContentNumber>
            {{ item.description }}
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Для кого</template>
        <template #body>
          <div
            v-for="(item, index) in store.state.sellers.sellers.fow_whom"
            :key="index"
            class="bonuses__grid"
          >
            <ContentNumber v-if="item.image">
              <img :src="mediaUrl(item.image)" width="32" height="32" alt="" />
            </ContentNumber>
            {{ item.description }}
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Как стать участником</template>
        <template #body>
          <div class="bonuses__grid">
            <ol>
              <li
                v-for="(item, index) in store.state.sellers.sellers
                  .how_to_involved"
                :key="index"
              >
                {{ item.description }}
              </li>
            </ol>
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Особенности</template>
        <template #body>
          <div class="bonuses__grid">
            <ul>
              <li
                v-for="(item, index) in store.state.sellers.sellers.features"
                :key="index"
              >
                {{ item.description }}
              </li>
            </ul>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import { mediaUrl } from "@/utils";
import { useStore } from "vuex";
import Collapse from "./Collapse.vue";
import ContentNumber from "./ContentNumber.vue";
import PromoItem from "./PromoItem.vue";

const store = useStore();
const promo = {
  color: "secondary",
  images: [
    require("@/assets/bonuses/m.webp"),
    require("@/assets/bonuses/d.webp"),
  ],
};
</script>

<style lang="scss" scoped>
.bonuses {
  margin-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  margin-bottom: var(--widthGapV);
  &__body {
    display: grid;
    gap: 8px;
  }
  &__grid {
    display: grid;
    gap: var(--widthGapV);
  }
}
</style>
