<template>
  <div class="mixx-banner">
    <div class="mixx-banner__content">
      <h3 class="mixx-banner__title">С подпиской MiXX выгода до 3 675 ₽</h3>
    </div>
    <div class="mixx-banner__container">
      <Swiper v-bind="swiperOptions" class="mixx-banner__slider">
        <SwiperSlide
          v-for="(item, i) of items"
          :key="i"
          class="mixx-banner__slide"
        >
          <div class="mixx-banner__item item-mixx">
            <h3 class="item-mixx__title" v-html="item.name"></h3>
            <div v-if="item.price" class="item-mixx__price">
              {{ item.price }}&nbsp;₽
            </div>
            <div v-else class="item-mixx__non-price"></div>
            <img class="item-mixx__image" :src="item.image" alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

/**
 * @type {import("swiper").SwiperOptions}
 */
const swiperOptions = {
  slidesPerView: "auto",
  breakpoints: {
    0: {
      spaceBetween: 8,
    },
    768: {
      spaceBetween: 12,
    },
  },
  navigation: {
    prevEl: ".mixx-banner__navgation-item.prev",
    nextEl: ".mixx-banner__navgation-item.next",
  },
  modules: [Navigation],
};

/**
 * @type {{name: string, price: number, image: string}[]}
 */
const items = [
  {
    name: "+50 ГБ<br />каждый<br />месяц",
    price: 750,
    image: require("@/assets/mixx/50Gb@2x.png"),
  },
  {
    name: "Wink",
    price: 199,
    image: require("@/assets/mixx/Wink_48.png"),
  },
  {
    name: "Яндекс Плюс",
    price: 399,
    image: require("@/assets/mixx/yandex-mixx.svg"),
  },
  {
    name: "ВТБ‑Вклад MiXX – 24,5% годовых",
    price: 0,
    image: require("@/assets/mixx/vtb-mixx-max.png"),
  },
  {
    name: "Кешбэк<br />сервис<br />«Пакет»",
    price: 199,
    image: require("@/assets/mixx/paketX5GrayBorder.png"),
  },
  {
    name: "Ozon Premium",
    price: 199,
    image: require("@/assets/mixx/ozon.png"),
  },
  {
    name: "VK Музыка",
    price: 169,
    image: require("@/assets/mixx/vk-music.svg"),
  },
  {
    name: "PREMIER",
    price: 299,
    image: require("@/assets/mixx/Premier@2x.png"),
  },
  {
    name: "Литрес",
    price: 399,
    image: require("@/assets/mixx/Litres@2x.png"),
  },
  {
    name: "Kaspersky Standard",
    price: 167,
    image: require("@/assets/mixx/Kaspersky@2x.png"),
  },
  {
    name: "+5% Выгодно вместе",
    price: 50,
    image: require("@/assets/mixx/VigodnoVmeste@2x.png"),
  },
  {
    name: "Сервисы t2",
    price: 365,
    image: require("@/assets/mixx/ServicesTele2@2x.png"),
  },
  {
    name: "Скидка 15% Lamoda",
    price: 450,
    image: require("@/assets/mixx/Lamoda@2x.png"),
  },
  {
    name: "Повышенный кэшбэк",
    price: 30,
    image: require("@/assets/mixx/Bolshe@2x.png"),
  },
  {
    name: "Выгода в обменах минут и ГБ",
    price: 0,
    image: require("@/assets/mixx/PovishenniyKursObmena@2x.png"),
  },
];
</script>

<style lang="scss" scoped>
.mixx-banner {
  padding: 24px 0 24px 16px;
  border-radius: 16px;
  background: url("@/assets/mixx/background-mobile-dark.svg");
  background-size: cover;
  background-repeat: no-repeat;
  &__content {
  }
  // .mixx-banner__title
  &__title {
    margin-bottom: 16px;
    font-family: var(--fontPrimary);
    text-transform: unset;
  }
  // .mixx-banner__desc
  &__desc {
    margin-bottom: 16px;
  }
  // .mixx-banner__container
  &__container {
    display: grid;
  }
  // .mixx-banner__slider
  &__slider {
    max-width: 100%;
  }
  // .mixx-banner__slide
  &__slide {
    width: auto;
  }
  // .mixx-banner__item
  &__item {
    width: 148px;
    flex: 0 0 148px;
    height: 98px;
  }
  // .mixx-banner__navigation
  &__navigation {
    display: none;
  }
}
.item-mixx {
  position: relative;
  overflow: hidden;
  padding: 12px 12px 30px;
  border-radius: 12px;
  border: 1.5px solid #3fcbff;
  // .item-mixx__title
  &__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-family: var(--fontPrimary);
    color: #fff;
    text-transform: none;
  }
  // .item-mixx__non-price
  &__non-price {
    font-size: 13px;
    line-height: 18px;
    color: #8c92a1;
    position: absolute;
    left: 12px;
    bottom: 12px;
  }
  // .item-mixx__price
  &__price {
    @extend .item-mixx__non-price;

    &::before {
      content: "";
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      border-top: 1px solid #ff59a3;
      transform: rotate(-20deg);
    }
  }
  // .item-mixx__image
  &__image {
    position: absolute;
    width: 48px;
    border-radius: 48px;
    right: -6px;
    bottom: -6px;
  }
}

@include lg {
  .mixx-banner {
    padding: 40px 0 40px 40px;
    background: url("@/assets/mixx/background-desktop-dark.svg");
    background-size: cover;
    background-repeat: no-repeat;
    &__content {
    }
    // .mixx-banner__title
    &__title {
      margin: 0px 0px 24px 0px;
    }
    // .mixx-banner__desc
    &__desc {
      margin: 0px 0px 24px 0px;
      font-size: 16px;
      line-height: 16px;
    }
    // .mixx-banner__container
    &__container {
    }
    // .mixx-banner__slider
    &__slider {
    }
    // .mixx-banner__slide
    &__slide {
    }
    // .mixx-banner__item
    &__item {
    }
    // .mixx-banner__navigation
    &__navigation {
    }
    // .mixx-banner__navgation-item
    &__navgation-item {
      width: 50px;
      height: 50px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  .item-mixx {
    // .item-mixx__title
    &__title {
    }
    // .item-mixx__price
    &__price {
    }
    // .item-mixx__image
    &__image {
      position: absolute;
      width: 48px;
      border-radius: 48px;
      right: -6px;
      bottom: -6px;
    }
  }
}
</style>
