<template>
  <div class="faq">
    <Collapse
      v-for="item in store.state.faq.faq"
      :key="item.question"
      color="grey"
    >
      <template #head>{{ item.question }}</template>
      <template #body>
        <div v-html="item.answer"></div>
      </template>
    </Collapse>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import Collapse from "./Collapse.vue";

const store = useStore();
</script>

<style lang="scss" scoped>
.faq {
  display: grid;
  gap: 8px;
  &:deep(.card) {
    border-radius: var(--radiusOuter);
  }
}
</style>
