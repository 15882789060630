<template>
  <a :href="link" target="_blank" rel="noopener noreferrer" class="qr-link">
    <div class="qr-link__img">
      <img :src="src" alt="" />
    </div>
    <div class="qr-link__caption">Нажмите для <br />перехода</div>
  </a>
</template>

<script>
export default {
  props: {
    src: String,
    link: String,
  },
};
</script>

<style lang="scss" scoped>
.qr-link {
  max-width: 200px;
  margin: 0 auto;
  display: grid;
  gap: 16px;
  text-align: center;
  align-self: center;
  &__img {
    background: #ffffff;
    border-radius: var(--radiusOuter);
    position: relative;
    padding: 24px;
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      display: block;
    }
  }
  &__caption {
    font-weight: 800;
    font-family: var(--fontSecondary);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
  }
}
</style>
