<template>
  <!-- <RegionConfirm
    v-if="!region && showRegionConfirm && modalShowRegionConfirm"
    :location="modalShowLocation"
    @show-location="modalShowLocation = true"
  /> -->
  <div class="header">
    <!-- <HeaderNav v-if="breakpointsDesktop.includes(screenSize)" /> -->
    <header class="header">
      <div class="header__inner">
        <router-link class="header__logo" :to="{ name: 'Home' }">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M5.98866 12.8191H0V18.8437H5.98866V12.8191Z"
              fill="#FF3495"
            />
            <path
              d="M13.5871 28.9473V25.6476C13.5871 25.6406 13.5871 25.6337 13.5871 25.6268V18.8436H19.5758V12.8191H13.5871V5.99994H7.5625V25.6268C7.5625 30.7781 11.7535 34.9691 16.9048 34.9691H21.8433V28.9473H13.5885H13.5871Z"
              fill="white"
            />
            <path
              d="M39.5289 28.9456H23.4146V34.9702H39.5289V28.9456Z"
              fill="white"
            />
            <path
              d="M39.9988 18.842C39.9988 13.6493 35.7747 9.42517 30.5819 9.42517C25.3892 9.42517 21.165 13.6493 21.165 18.842H27.1896C27.1896 16.9711 28.7124 15.4484 30.5833 15.4484C32.4542 15.4484 33.977 16.9711 33.977 18.842C33.977 20.713 32.4542 22.2357 30.5833 22.2357H23.4174V28.2603H28.1831L31.0863 28.2465C36.0455 27.9839 40.0002 23.8676 40.0002 18.8434L39.9988 18.842Z"
              fill="white"
            />
          </svg>
        </router-link>
        <div class="header__right">
          <button
            v-show="breakpointsDesktop.includes(screenSize)"
            @click="modalShowLocation = true"
            class="header-location"
          >
            {{ currentRegion || "Москва и область" }}
          </button>
          <!-- <button @click="modalShowNotify = true" class="header__notify">
            <div class="bell__wrapper">
              <Bell :has-new-nots="hasMessages" />
            </div>
          </button> -->
          <button
            v-if="modalShowNav"
            v-show="breakpointsMobile.includes(screenSize)"
            @click="modalShowNav = false"
            class="header__close"
          ></button>
          <button
            v-else
            v-show="breakpointsMobile.includes(screenSize)"
            @click="modalShowNav = true"
            class="header__menu"
          ></button>
          <ModalMessage
            v-if="modalShowMessage"
            text="Добавили новый тариф «Мой Онлайн+» в раздел «Тарифные планы»"
            to="/"
            @close="onCloseMessage"
          />
        </div>
      </div>
    </header>
  </div>
  <AsyncModal v-if="modalShow" @close="onClose" />
  <ModalNav
    v-if="modalShowNav"
    :location="modalShowLocation"
    :current="currentRegion"
    @close="onCloseNav"
    @show-location="modalShowLocation = true"
  />
  <!-- <ModalNotify v-if="modalShowNotify" @close="onCloseNotify" /> -->
  <RegionConfirmModal
    v-if="!region && !modalShowRegionConfirm"
    @close="onCloseModalRegionConfirm"
    @changeRegion="openLocationModal"
  />
  <ModalLocation
    v-if="modalShowLocation"
    @close="onCloseLocation"
    :current="currentRegion"
  />
</template>

<script>
// import Bell from "@/components/Bell";
import ModalLocation from "@/components/ModalLocation.vue";
import ModalNav from "@/components/ModalNav.vue";
// import ModalNotify from "@/components/ModalNotify.vue";
// import RegionConfirm from "@/components/RegionConfirm.vue";
import RegionConfirmModal from "@/components/RegionConfirmModal.vue";
import { regions } from "@/constants";
import store from "@/store";
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";
// import HeaderNav from "./HeaderNav.vue";
import ModalMessage from "./ModalMessage.vue";

export default {
  name: "HeaderComponent",
  components: {
    // Bell,
    AsyncModal: defineAsyncComponent(() =>
      import("@/components/ModalSearch.vue")
    ),
    ModalNav,
    // ModalNotify,
    ModalLocation,
    // RegionConfirm,
    // HeaderNav,
    ModalMessage,
    RegionConfirmModal,
  },
  data() {
    return {
      modalShow: false,
      modalShowNav: false,
      modalShowNotify: false,
      modalShowLocation: false,
      modalShowMessage: false,
      modalShowRegionConfirm: false,
      showRegionConfirm: true,
      regions,
    };
  },
  computed: {
    ...mapState({
      region: (state) => state.region.region,
      regionConfirm: (state) => state.api.regionConfirm,
      hasMessages: (state) => state.notifications.hasNewNotifications,
    }),
    currentRegion() {
      return this.regions[this.region]?.name || this.regionConfirm?.name;
    },
  },
  methods: {
    onClose() {
      this.modalShow = false;
    },
    onCloseNav() {
      this.modalShowNav = false;
    },
    onCloseNotify() {
      this.modalShowNotify = false;
      store.commit("notifications/setHasNots", false);
    },
    onCloseLocation() {
      this.modalShowLocation = false;
    },
    onCloseMessage() {
      this.modalShowMessage = false;
    },
    onCloseModalRegionConfirm() {
      this.modalShowRegionConfirm = true;
    },
    openLocationModal() {
      this.modalShowRegionConfirm = true;
      this.modalShowLocation = true;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  height: var(--heightHeader);
  background: var(--colorBlack);
  display: grid;
  place-items: center;
  padding: 0 var(--widthGutter);
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    position: relative;
    top: 2px;
    z-index: 3010;
    svg {
      @include md {
        width: 56px;
        height: 56px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    a {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 0;
      @include md {
        margin-right: 34px;
      }
    }
  }
  &__notify {
    @include reset-button;
    width: 45px;
    height: var(--heightHeader);
    display: grid;
    place-items: center;
  }
  &__menu {
    @include reset-button;
    width: 45px;
    height: var(--heightHeader);
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 1.5c0-.5523.4477-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Zm0 6c0-.5523.4477-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Zm1 5c-.5523 0-1 .4477-1 1s.4477 1 1 1h16c.5523 0 1-.4477 1-1s-.4477-1-1-1H1Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: -4px;
    margin-right: -13px;
  }
  &__close {
    @include reset-button;
    width: 45px;
    height: var(--heightHeader);
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: -4px;
    margin-right: -13px;
    // position: relative;
    position: fixed;
    top: 0;
    z-index: 3010;
  }
  &-location {
    @include reset-button;
    font-size: 12px;
    line-height: 32px;
    color: var(--colorWhite);
    width: 100%;
    text-align: left;
    padding-left: 38px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='32' fill='none'%3E%3Cpath fill='%23fff' d='M15.835 28.686c.216.209.5.314.782.314.282 0 .566-.105.782-.314l.004-.004c.426-.412 9.214-8.902 9.214-15.833 0-6.7-5.184-9.849-10-9.849s-10 3.15-10 9.848c0 6.932 8.788 15.422 9.214 15.834l.004.004Z'/%3E%3Ccircle cx='16.617' cy='12' r='4' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}
</style>
