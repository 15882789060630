<template>
  <Card class="collapse" :color="color" :class="{ collapsed }">
    <button type="button" class="collapse__head" @click="onClick">
      <slot name="head" />
      <CollapseSVG :collapsed="collapsed" />
    </button>
    <div v-if="collapsed" class="collapse__body">
      <slot name="body" />
    </div>
  </Card>
</template>

<script setup>
import { ref } from "vue";
import Card from "./Card.vue";
import CollapseSVG from "./CollapseSVG.vue";

const props = defineProps({
  color: {
    type: String,
    default: "white",
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
});

const collapsed = ref(props.collapsed);

const onClick = () => (collapsed.value = !collapsed.value);
</script>
<script>
export default {
  name: "CollapseComponent",
};
</script>

<style scoped lang="scss">
.collapse {
  &.inset {
    @include md {
      border-radius: 18px;
    }
  }
  &.black,
  &.grey-dark {
    &:deep(.collapse__head svg) {
      .bg {
        fill: var(--colorWhite);
        stroke: var(--colorWhite);
      }
    }
  }
  &.grey-light {
    &:deep(.collapse__head svg) {
      .bg {
        fill: var(--colorWhite);
        stroke: var(--colorWhite);
      }
    }
  }
  &.white {
    &:deep(.collapse__head svg) {
      .bg {
        fill: var(--colorGrey);
        stroke: var(--colorGrey);
      }
    }
  }
  &.grey {
    background-color: var(--colorGrey);
    &:deep(.collapse__head svg) {
      .bg {
        fill: var(--colorBlack);
        stroke: var(--colorBlack);
      }
      .plus {
        fill: var(--colorWhite);
      }
    }
    &.collapsed {
      background-color: #f8f9fa;
      &:deep(.collapse__head svg) {
        .bg {
          fill: transparent;
          stroke: var(--colorBlack);
        }
        .plus {
          fill: var(--colorBlack);
        }
      }
    }
  }
  &__head {
    @include reset-button;
    margin: calc(var(--widthPadding) * -1);
    padding: var(--widthPadding);
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    width: calc(100% + var(--widthPadding) * 2);
    text-align: left;
    gap: var(--widthGapV);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
    font-family: var(--fontSecondary);
    font-weight: 800;
    align-items: center;
  }
  &__body {
    padding-top: 8px;
    @include md {
      padding-top: 12px;
    }
    &:deep(ul),
    &:deep(ol) {
      padding-left: 20px;
      margin: 5px 0;
      li + li {
        margin: 5px 0;
      }
      + p {
        margin-top: 10px;
      }
    }
    &:deep(p) {
      + p {
        margin-top: 10px;
      }
    }
    &:deep(a) {
      text-decoration: underline;
      color: var(--colorPink);
    }
    &:deep(img) {
      max-width: 100%;
      height: auto;
      + img {
        margin-top: 10px;
      }
    }
  }
}
</style>
