<template>
  <div class="swiper-pagination"></div>
</template>

<style lang="scss" scoped>
.swiper-pagination {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  width: 100%;
  @include md {
    position: absolute;
    top: 0;
    right: 0;
    margin: 24px;
    width: auto;
  }
  &:deep(span) {
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.3);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin: 0 2px;
    &.swiper-pagination-bullet-active {
      background: rgba(0, 0, 0, 1);
    }
  }
}
</style>
