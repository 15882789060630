<template>
  <div class="technology">
    <PromoItem
      class="technology__head"
      :color="promo.color"
      :title="promo.title"
      :caption="promo.desc"
      :images="promo.images"
    />
    <div class="technology__body">
      <Collapse color="grey">
        <template #head>Преимущества</template>
        <template #body>
          <div class="technology__grid">
            <ContentItem
              v-for="(benefit, index) in technology.benefits"
              :key="index"
              color="white"
            >
              <template #body>
                <div class="technology__title">
                  {{ benefit.title }}
                </div>
                <p>
                  {{ benefit.text }}
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Доступно для устройств</template>
        <template #body>
          <div class="technology__grid">
            <ContentItem
              v-for="(device, index) in technology.devices"
              :key="index"
              color="white"
            >
              <template #body>
                <div class="technology__img">
                  <img :src="mediaUrl(device.logo)" alt="" />
                </div>
                <ul>
                  <li v-for="(item, index) in device.options" :key="index">
                    {{ item }}
                  </li>
                </ul>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Подключение новому клиенту</template>
        <template #body>
          <div class="technology__grid">
            <ContentItem
              v-for="(step, index) in technology.new_sub"
              :key="index"
              color="white"
            >
              <template #head>
                {{ step.step }}
              </template>
              <template #body>
                <div class="technology__title">
                  {{ step.title }}
                </div>
                <div v-html="step.text"></div>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse v-if="technology.activation" color="grey">
        <template #head>Активация eSIM</template>
        <template #body>
          <p>
            {{ technology.activation.title }}
          </p>
          <p>
            <img
              :src="mediaUrl(technology.activation.image)"
              width="340"
              alt=""
            />
            <img
              :src="mediaUrl(technology.activation.image2)"
              width="340"
              alt=""
            />
          </p>
          <div v-html="technology.activation.steps"></div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Решение проблем</template>
        <template #body>
          <div class="technology__grid">
            <ContentItem
              v-for="(troubleshoot, index) in technology.troubleshoots"
              :key="index"
              color="white"
            >
              <template #body>
                <div class="technology__title">
                  {{ troubleshoot.title }}
                </div>
                <p>
                  {{ troubleshoot.text }}
                  <a
                    v-if="troubleshoot.link"
                    :href="troubleshoot.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ troubleshoot.link_text }}</a
                  >
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import Collapse from "./Collapse.vue";
import ContentItem from "./ContentItem.vue";
import PromoItem from "./PromoItem.vue";

const promo = {
  color: "black",
  title: `технология esim`,
  desc: `Это цифровая SIM-карта, которая не требует наличия пластиковой физической SIM-карты.  eSIM устанавливается на телефон с помощью QR-кода, предоставленного t2, и хранится в цифровом виде на устройстве.`,
  images: [require("@/assets/esim/m.webp"), require("@/assets/esim/d.webp")],
};
</script>
<script>
import { mediaUrl } from "@/utils";
import { mapState } from "vuex";

export default {
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  data() {
    return {
      collapsed1: false,
      collapsed2: false,
      collapsed3: false,
      collapsed4: false,
      collapsed5: false,
    };
  },
  computed: {
    ...mapState({
      technology: (state) => state.esim.esim,
    }),
  },
};
</script>
<style lang="scss" scoped>
.technology {
  margin-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &__body {
    display: grid;
    gap: 8px;
  }
  &__grid {
    display: grid;
    gap: var(--widthGapV);
    max-width: 1004px;
    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
    &:deep(img) {
      width: auto;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 14px;
    font-weight: 800;
    font-family: var(--fontSecondary);
  }
}
</style>
