<template>
  <div class="tariffs">
    <TariffItem
      v-for="(item, index) in filteredTariffs"
      :key="index"
      :item="item"
    ></TariffItem>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TariffItem from "./TariffItem.vue";

export default {
  components: {
    TariffItem,
  },
  data() {
    return {
      socialTariff: {
        name: "Социальный",
        hit: { color: "#ff3495", text: "Эксклюзив" },
        slug: "social",
        url: "",
        price: 150,
        freezePrice: {
          frontName:
            '<a href="https://freeze.t2.ru/" target="_blank">Заморозка цены</a>',
          icon: "https://t2.ru/api/media/asset?mediaId=m6660005",
        },
        unlimitedTele2_calls:
          "<strong>безлимит</strong> на t2 домашнего региона",
        minutes: {
          value: "100",
          frontName: "минут на номера домашнего региона и t2 России",
        },
        internetPackage: { uom: "ГБ", value: 3, bonus: false },
        messagesPackage: {
          uom: "SMS",
          frontName: "на все номера домашнего региона",
          value: "100",
        },
        internetTele2_home: null,
        features: {
          messengers: { frontName: null, icon: null },
          socialNetworks: { frontName: null, icon: null },
          advantages: [
            {
              frontName: "WhatsApp и еще 2 безлимитных мессенджера",
              icon: require("@/assets/icons/social-media/whatsapp2.svg"),
              priority: 10,
            },
            {
              frontName: "ВКонтакте и еще 1 безлимитная соцсеть",
              icon: "https://t2.ru/api/media/asset?mediaId=m4580003",
              priority: 30,
            },
            {
              frontName:
                "Безлимитный интернет на Яндекс.Карты и Яндекс.Навигатор",
              icon: require("@/assets/icons/social-media/yandex-map.svg"),
              priority: 30,
            },
          ],
          gamingAdvantages: [],
        },
        containerParams: {
          internetFree: `WhatsApp и еще 2 безлимитных мессенджера, ВКонтакте и еще 1 безлимитная соцсеть, безлимитный интернет на Яндекс.Карты и Яндекс.Навигатор`,
          messengers: [
            {
              selectedIcon: "/api/media/asset?mediaId=m1120011",
            },
            {
              selectedIcon: "/api/media/asset?mediaId=m4580003",
            },
            {
              selectedIcon: require("@/assets/icons/social-media/yandex-map.svg"),
              local: true,
            },
          ],
        },
      },
      everywhereOnlineAdvantages: [
        {
          frontName: "Telegram и еще 2 безлимитных мессенджера",
          icon: "https://t2.ru/api/media/asset?mediaId=m3620012",
          priority: 10,
        },
        {
          frontName: "ВКонтакте и еще 1 безлимитная соцсеть",
          icon: "https://t2.ru/api/media/asset?mediaId=m4580003",
          priority: 10,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      tariffs: function (state) {
        const copyTariffs = structuredClone(state.api.tariffs);
        const t = copyTariffs.find((t) => t.slug === "everywhere-online");
        if (t?.features?.advantages) {
          t.features.advantages = this.everywhereOnlineAdvantages.concat(
            t.features.advantages
          );
        }
        return copyTariffs;
      },
      status: (state) => state.api.tariffsStatus,
    }),
    filteredTariffs() {
      if (!this.tariffs.length) return [];

      const tariffsToShow = [
        "social",
        "enough",
        "black",
        "super-online-plus",
        "my-online-plus",
        "my-online",
        "everywhere-online",
        "my-conversation",
        "gaming",
        "premium",
      ];

      return [this.socialTariff, ...this.tariffs].filter((t) =>
        tariffsToShow.includes(t.slug)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  display: grid;
  gap: var(--widthGapH) var(--widthGapV);
  margin-bottom: var(--widthGapV);
  @include md {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
