export default {
  namespaced: true,
  state: {
    selfReg: {
      title: `SIM-карты <span>t2</span> с саморегистрацией \nв приложении «<span>t2</span>»`,
    },
  },
  mutations: {
    setEsim(state, value) {
      state.esim = value;
    },
  },
};
