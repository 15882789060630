export default {
  namespaced: true,
  state: {
    apps: {},
  },
  mutations: {
    setApps(state, value) {
      state.apps = value;
    },
  },
};
