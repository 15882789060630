import Mark from "mark.js/src/lib/mark";

// брейкпоинты,
export const breakpoints = {
  xs: 0,
  sm: 568,
  md: 768,
  lg: 1024,
  xl: 1440,
};

const baseURL = process.env.VUE_APP_MEDIA_URL;

export const mediaUrl = (url) => `${baseURL}${url}`;

export const imageEndpoint = `${process.env.VUE_APP_BASE_API}/api/guidebookv3/public/image/`;

export function validateStatus(status) {
  return status >= 200 && status < 300;
}

export function watchScreenResize(store) {
  function onResize() {
    const width = window.innerWidth;
    if (width >= breakpoints.xl) {
      store.commit("device/setScreenSize", "xl");
    } else if (width >= breakpoints.lg && width < breakpoints.xl) {
      store.commit("device/setScreenSize", "lg");
    } else if (width >= breakpoints.md && width < breakpoints.lg) {
      store.commit("device/setScreenSize", "md");
    } else if (width >= breakpoints.sm && width < breakpoints.md) {
      store.commit("device/setScreenSize", "sm");
    } else {
      store.commit("device/setScreenSize", "xs");
    }
  }

  onResize();
  window.addEventListener("resize", onResize, false);
}

export function compareArrays(arr1, arr2) {
  let newNotifications = [];

  for (let indexArr1 = 0; indexArr1 < arr1.length; indexArr1++) {
    let index = false;
    for (let indexArr2 = 0; indexArr2 < arr2.length; indexArr2++) {
      if (JSON.stringify(arr1[indexArr1]) === JSON.stringify(arr2[indexArr2])) {
        index = true;
      }
    }
    if (!index) newNotifications.push(arr1[indexArr1]);
  }

  return !!newNotifications.length;
}

export function highlight(url, text) {
  // разделяем массивы чтобы отделить перечеркнутые слова от обычных
  let nonStrikedStrings = text.filter((item) => {
    return !item.includes("span");
  });
  let strikedStrings = text.filter((item) => {
    return item.includes("span");
  });

  let strikedStringsToMark = strikedStrings.map((item) =>
    item.replace(/<\/?[^>]+(>|$)/g, "")
  );

  let instance = new Mark(document.querySelector(`#${url}`));

  // маркируем обычные слова
  instance.mark(nonStrikedStrings, {
    element: "mark",
    className: "highlight",
    separateWordSearch: false,
    each: (item) => {
      if (item.parentNode.style.textDecoration === "line-through") {
        item.classList.remove("highlight");
      } else {
        searchForCollapse(item);
      }
    },
  });
  // маркируем зачеркнутые слова
  instance.mark(strikedStringsToMark, {
    element: "mark",
    className: "highlight",
    separateWordSearch: false,
    each: (item) => {
      // исключаем слова, span которых не имеет стиль line-through
      if (item.parentNode.style.textDecoration !== "line-through") {
        item.classList.remove("highlight");
      } else {
        searchForCollapse(item);
      }
    },
  });
}

const openedCollapses = [];

// рекурсия для поиска collapse-head и нажатия для него, либо нативного открытия добавлением класса
function searchForCollapse(node) {
  let currentNode = node;
  while (currentNode.className !== "home-section") {
    currentNode = currentNode.parentNode;
    if (openedCollapses.find((item) => item === currentNode)) break;
    if (
      currentNode.classList.contains("collapse") ||
      currentNode.classList.contains("offer-item-collapse")
    ) {
      if (
        currentNode.classList.contains("collapsed") ||
        currentNode.childNodes[0].classList.contains("open")
      )
        return;
      if (
        currentNode.classList.contains("offer-item-collapse") &&
        !currentNode.childNodes[0].classList.contains("open")
      ) {
        currentNode.childNodes[0].classList.add("open");
        currentNode.childNodes[1].classList.add("collapsed");
      }
      openedCollapses.push(currentNode);
      currentNode.childNodes[0].click();
      return;
    }
  }
}

export function divide(text) {
  let newSTR = text.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");

  return newSTR.split("\n");
}
