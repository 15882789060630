<template>
  <div class="personal">
    <div class="personal__grid">
      <Card color="grey" class="personal__card">
        <h3>вариант 1</h3>
        <h5>Установите приложение «<span>t2</span>»</h5>
        <p>
          Включите камеру своего смартфона и отсканируйте QR-код. Он приведет
          вас в нужный раздел приложения. Выберите удобный для вас способ
          актуализации данных и пройдите несколько простых шагов.
        </p>
      </Card>
      <Card color="green">
        <QRLink link="https://q.t2.ru/lk" src="/personal/Layer_1.png" />
      </Card>
    </div>
    <Card color="grey" class="personal__card">
      <h3>вариант 2</h3>
      <h5>Войдите в приложение «<span>t2</span>»</h5>
      <ContentSwiper>
        <SwiperSlide v-for="(slide, i) of slides" :key="i">
          <ContentItem color="white">
            <template #head>{{ i + 1 }}</template>
            <template #body>
              <p>
                {{ slide.title }}
              </p>
              <img :src="slide.image" alt="" />
            </template>
          </ContentItem>
        </SwiperSlide>
      </ContentSwiper>
    </Card>
  </div>
</template>

<script setup>
import QRLink from "@/components/QRLink.vue";
import { SwiperSlide } from "swiper/vue";
import Card from "./Card.vue";
import ContentItem from "./ContentItem.vue";
import ContentSwiper from "./ContentSwiper.vue";

const slides = [
  {
    title: "Войдите в свой профиль на главном экране приложения.",
    image: require("@/assets/pd/1.webp"),
  },
  {
    title:
      "Нажмите на информационное сообщение или выберите раздел «Паспортные данные».",
    image: require("@/assets/pd/2.webp"),
  },
  {
    title: "Выберите удобный для вас способ актуализации данных.",
    image: require("@/assets/pd/3.webp"),
  },
];
</script>

<style lang="scss" scoped>
.personal {
  display: grid;
  gap: var(--widthGapH);
  &__grid {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: 1fr auto;
      gap: var(--widthGapV);
    }
  }
  &__card {
    display: grid;
    align-content: start;
    gap: var(--widthGapV);
  }
}
</style>
