<template>
  <div class="offers">
    <Card color="grey-dark" class="offers-promo">
      <h3>{{ offers.subtitle }}</h3>
      <img
        srcset="@/assets/mixx/m.webp 654w, @/assets/mixx/d.webp 722w"
        src="@/assets/mixx/m.webp"
        sizes="(max-width: 767px) 0px"
        alt=""
        width="327"
        height="76"
      />
      <p>{{ offers.subtitle2 }}</p>
    </Card>
    <div class="offers-list">
      <swiper
        :modules="modules"
        :slides-per-view="'auto'"
        :slidesOffsetBefore="breakpointsMobile.includes(screenSize) ? 12 : 0"
        :slidesOffsetAfter="breakpointsMobile.includes(screenSize) ? 12 : 0"
        :space-between="16"
        :pagination="breakpointsMobile.includes(screenSize)"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="(item, key) of offers.subscriptions" :key="key">
          <offer-item
            :mix="item"
            :collapsed1="collapsed1"
            :collapsed2="collapsed2"
            @changeCollapsed1="collapsed1 = !collapsed1"
            @changeCollapsed2="collapsed2 = !collapsed2"
            :class="{ primary: offers.subscriptions.length - 1 === key }"
          />
        </swiper-slide>
      </swiper>
    </div>
    <Collapse color="grey-dark">
      <template #head>Как подключить MiXX</template>
      <template #body>
        <div v-html="offers.how_to_activation"></div>
      </template>
    </Collapse>
    <MixxBanner class="bonuses-banner" />
  </div>
</template>

<script>
import { ref } from "vue";

import MixxBanner from "@/components/MixxBanner.vue";
import { mediaUrl } from "@/utils";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapState } from "vuex";
import Card from "./Card.vue";
import Collapse from "./Collapse.vue";
import OfferItem from "./OfferItem.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    OfferItem,
    MixxBanner,
    Card,
    Collapse,
  },
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  data() {
    return {
      collapsed: false,
      collapsed1: false,
      collapsed2: false,
    };
  },
  computed: {
    ...mapState({
      offers: (state) => state.promo.promo,
    }),
  },
  setup() {
    const mySwiper = ref(null);
    const onSwiper = (swiper) => {
      mySwiper.value = swiper;
    };

    return {
      mySwiper,
      onSwiper,
      modules: [Pagination],
    };
  },
};
</script>

<style lang="scss" scoped>
.offers {
  display: grid;
  gap: var(--widthGapH);
  &-promo {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
    img {
      display: block;
      margin: auto;
      max-width: 100%;
      height: auto;
      @include md {
        width: 361px;
        height: 96px;
        justify-self: end;
        grid-row: 2 span;
        align-content: center;
        margin: -7px 0;
      }
    }
  }
  &-list {
    overflow: hidden;
    color: var(--colorBlack);
    margin: 0 calc(var(--widthGapV) * -1);
    @include md {
      margin: 0;
    }
    :deep(.swiper-slide) {
      width: 271px;
    }
  }
}
</style>
